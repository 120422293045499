'use client'
import { createContext } from 'react'
import { useDisclosure } from '@chakra-ui/react'
export const GeneralContext = createContext({
  options: null,
  page: null,
  isOpen: null,
  onOpen: null,
  onClose: null,
})

const GeneralProvider = ({ options, page, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: 'restart-modal',
  })

  return (
    <GeneralContext.Provider
      value={{
        options,
        page,
        isOpen,
        onOpen,
        onClose
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}

export default GeneralProvider
