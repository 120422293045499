'use client'
import React, { useContext } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  HStack,
} from '@chakra-ui/react'

import { APP_ROUTES } from '@/lib/constants'
import { GeneralContext } from '@/contexts/General'
import { useRouter } from 'next/navigation'
import Cta from '../Cta'

const styles = {
  title: {
    textAlign: 'center',
    fontSize: {
      base: '32px',
      md: '48px'
    }
  },
  description: {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '180%'
  }
}
const copy = {

  title: 'Vuoi chiedere a Delphi di generare un nuovo scenario?',
  description: 'Ricominciando dall\'inizio perderai tutti i progressi e la generazione di Delphi. <br /> Meglio continuare e poi iniziarne una nuova.',
  restart: 'Ricomincia dall’inizio',
  continue: 'Continua con l’attuale configurazione'
}

const Restart = () => {
  const { isOpen, onClose } = useContext(GeneralContext)
  const router = useRouter()

  const onRestart = () => {
    onClose()
    router.push(APP_ROUTES.HOME)
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(10px)'
      >
        <ModalContent
          maxWidth={'1000px'}
          padding={{
            base: '16px',
            md: '64px'
          }}
          containerProps={{
            padding: '24px'
          }}
        >
          <ModalCloseButton />
          <ModalBody
            overflow={'auto'}
          >
            <VStack gap={{
              base: '24px',
              md: '32px'
            }}>
              <Text {...styles.title}>{copy.title}</Text>
              <Text
                {...styles.description}
                dangerouslySetInnerHTML={{ __html: copy.description }}
              />
              <HStack
                gap={{
                  base: '24px',
                  md: '32px'
                }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Cta type="button" onClick={onRestart} secondary>{copy.restart}</Cta>
                <Cta type="button" onClick={onClose} primary>{copy.continue}</Cta>
              </HStack>
            </VStack>

          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default Restart
