'use client'

import { GeneralContext } from '@/contexts/General'
import { Button } from '@chakra-ui/react'
import { usePathname } from 'next/navigation'
import { useContext } from 'react'
const styles = {
  logo: {
    style: {
      backdropFilter: 'blur(5px)',
      // backgroundColor: '#eeeeee1a',
      backgroundColor: 'transparent',
      padding: '16px 10px',
      fontSize: '1.5rem',
      borderRadius: '.5rem',
    },
  },
}
const Logo = ({ logoText = 'Delphi' }) => {
  const { onOpen } = useContext(GeneralContext)
  const pathname = usePathname()

  const onClick = () => {
    if (pathname === '/') return

    onOpen()
  }

  return (
    <Button
      onClick={onClick}
      style={{ ...styles.logo.style }}
      fontFamily={'heading'}
    >
      {logoText}
    </Button>
  )
}

export default Logo
