'use client'
import GeneralProvider from '@/contexts/General'
import ResponsiveProvider from '@/contexts/Responsive'

import { client } from '@/lib/apolloClient'
import { theme } from '@/theme/chakraTheme'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'

const Providers = ({ seo, options, page, children }) => (
  <ChakraProvider theme={theme}>
    <GeneralProvider seo={seo} options={options} page={page}>
      <ResponsiveProvider>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </ResponsiveProvider>
    </GeneralProvider>
  </ChakraProvider>
)

export default Providers
