import React from 'react'

import { Text, UnorderedList, ListItem } from '@chakra-ui/react'

const styles = {
  title: {
    fontSize: '14px',
    fontWeight: '500',
  },
  list: {
    margin: 0,
    sx: {
      '> li + li ': {
        marginBlockStart: '16px',
      },
      'li > p': {
        marginBottom: '16px'
      },
      '&[data-parent="false"]': {
        '> li + li ': {
          marginBlockStart: '16px',
        },
        'li': {
          padding: '16px',
          borderRadius: '12px',
          border: '1px solid rgba(0, 0, 0, 0.10)',
          background: '#F2F2F2',
          margin: 0,
          p: {
            margin: 0
          }
        }
      },

      '&[data-level="0"] .list-data': {
        padding: '16px',
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.10)',
        background: '#F2F2F2',
        margin: 0,
      },
      '&[data-level="1"] .list-data': {
        padding: 0,
        border: 'none',
        background: 'transparent',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    }

  },
}
const ContentList = ({ title, data, level }) => {
  const newLevel = level + 1

  return (
    <>
      {
        title &&
        <Text {...styles.title}>{title}</Text>
      }

      {
        Array.isArray(data) &&
        !!data.length
          ? <List data={data} parent={false} level={newLevel} />
          : !!data && !!data.length && <Text className='list-data'>{data}</Text>
      }
    </>
  )
}

const List = ({ data = [], parent = true, level = 0 }) =>{
  return (
    data.length &&
    <UnorderedList data-parent={parent} data-level={level} {...styles.list}>
      {
        data.map((item, index) => {
          const itemData = typeof item === 'string' ? { data: item } : item
          return (
            <ListItem key={index}>
              <ContentList {...itemData} level={level}/>
            </ListItem>
          )
        })
      }
    </UnorderedList>
  )
}


export default List
