'use client'
import { createContext, useContext, useEffect, useState } from 'react'

const ResponsiveContext = createContext()

const ResponsiveProvider = ({ children }) => {
  const [sizes, setSizes] = useState({})

  useEffect(() => {
    const updateSizes = () =>
      setSizes({ width: innerWidth, height: innerHeight })
    updateSizes()

    window.addEventListener('resize', updateSizes)
    return () => window.removeEventListener('resize', updateSizes)
  }, [])

  return (
    <ResponsiveContext.Provider value={sizes}>
      {children}
    </ResponsiveContext.Provider>
  )
}

const Responsive = ({ on, children = null }) => {
  const sizes = useContext(ResponsiveContext)
  if (typeof on !== 'function') return children
  return on(sizes) ? children : null
}

export default ResponsiveProvider

export { Responsive, ResponsiveContext }
