'use client'
import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  HStack,
  Box,
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks'
import { usePathname } from 'next/navigation'

import { getLocalStorageItems } from '@/hooks/useLocalStorageState'
import List from '@/components/atoms/List'

import Cta from '@/components/molecules/Cta'


const titles = {
  SELECTED_SPHERES: 'Le tue sfere',
  SELECTED_AREAS: 'I tuoi ambiti',
  RAW_CONTEXT: 'Il contesto',
  PERSONAS: 'Le personas',
  QUESTIONS: 'Domande',
  // SUMMARY: 'Sommario',
}


const Recap = () => {
  const pathname = usePathname()
  const [data, setData] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()


  const getData = () => {
    const localStorageKeys = getLocalStorageItems()
    const titlesKeys = Object.keys(titles)

    const d = Object.keys(localStorageKeys).reduce((acc, el) => {
      if (!!titles[el]) {
        let storageKeyData = localStorageKeys[el]

        if (el === 'RAW_CONTEXT') {
          storageKeyData = localStorageKeys[el].summary
        }

        acc.push({
          idx: titlesKeys.indexOf(el),
          title: titles[el],
          data: storageKeyData,
        })
      }

      return acc
    }, []).sort((a, b) => a.idx - b.idx)

    setData(d)
  }


  const onClick = () =>{
    onOpen()
    getData()
  }

  React.useEffect(() => {
    if (pathname === '/') return
    getData()
  }, [pathname])

  if (pathname === '/') return <></>

  return (
    <Box backdropFilter='blur(5px)'>
      <Cta outlined onClick={onClick} padding="12px 18px">
        <HStack>

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 1V9" stroke="black" strokeLinecap="round"/>
            <path d="M17 9H21" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 20H5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19 1.5L14 4L19 6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 20H18C19.6569 20 21 18.6569 21 17V17C21 15.3431 19.6569 14 18 14H5.5C4.11929 14 3 12.8807 3 11.5V11.5C3 10.1193 4.11929 9 5.5 9H14" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          <span>Recap</span>
        </HStack>
      </Cta>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px)'
        >
          <ModalContent
            padding={{
              base: '16px',
              md: '32px'
            }}
            height={'100%'}
            margin={0}

            containerProps={{ justifyContent: 'flex-end', paddingRight: 0, overflow: 'hidden' }}>
            <ModalHeader textAlign={'right'} padding={0}>
              <Cta outlined onClick={onClose} padding="12px 18px">Chiudi</Cta>
            </ModalHeader>
            <ModalBody
              padding={0}
              overflow={'auto'}
            >
              <List data={data} />
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  )
}


export default Recap
